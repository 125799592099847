import React, { useState } from 'react';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import styles from './outlinedPasswordInputStyles.js';

export default function TextInput(props) {
    const classes = styles();
    const [showPassword, updateShowPassword] = useState(false);

    const onShowPasswordChange = () => {
        updateShowPassword(!showPassword);
    }

    return (
        <div className={classes.inputItem}>
            <TextField
                label={props.label}
                variant='outlined'
                className={props.className}
                disabled={props.disabled}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={onShowPasswordChange}
                                onMouseDown={onShowPasswordChange}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
}
