import React from 'react';

import App from './App';
import ReactDOM from 'react-dom';
import 'typeface-roboto';

import * as authHelper from './helpers/AuthenticationHelper';
import './scss/index.scss';
import * as serviceWorker from './serviceWorker';

import * as config from 'silly-stacks-ducks/Configurations';

config.configureClient('https://api.sillystacks.com/', authHelper.getAccessToken);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
