import { makeStyles } from "@material-ui/core/styles";

const registrationStyles ={
    root: {
        textAlign: 'center'
    },
    fullTextArea: {
        width: 340
    },
    button: {
        minWidth: '340px',
        marginTop: '10px'
    }
};

export default makeStyles(registrationStyles);