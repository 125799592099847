import React from 'react';
import { Card, CardActions, Grid, Typography, CardContent, Button } from '@material-ui/core';

import 'scss/pages/privacy.scss';

export default function Privacy() {
    return (
        <div className='main'>
            <Grid container justify='center' alignItems='center' direction="column">
                <Grid item xs={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Privacy Policy
                        </Typography>
                            <Typography variant="body2" component="p">
                                We do not sell any personally identifiable information. All data collected via our mobile app is stored on you device and is deleted when the app is uninstalled.
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
