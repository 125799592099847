import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {connectRouter} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import reducers from 'silly-stacks-ducks/';

export const history = createBrowserHistory();

let store = createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history),
    }),
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            promise
        )
    )
);

export default store;
