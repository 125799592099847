const ImmutableHelper = {
    object: {
        deepClone: (obj) => {
            if (obj) {
                var str = JSON.stringify(obj);
                return JSON.parse(str);
            }
            return obj;
        }
    },
    array: {
        insertItem: (array, item, index) => {
            let newArray = array.slice()

            if (index === undefined) {
                newArray.push(item);
            }
            else {
                newArray.splice(index, 0, item)
            }
            return newArray
        },
        removeItem: (array, index) => {
            let newArray = array.slice()
            newArray.splice(index, 1)
            return newArray
        }
    }

}

export default ImmutableHelper;