const order = (array, accessor) => {
    if (array && array.length > 0) {
        return array.sort((a, b) => accessor(b) > accessor(a) ? -1 : 1);
    }
    return array;
}


const Sort = {
    orderByDescending(array, accessor) {
        return order(array, accessor).reverse();
    },
    orderBy(array, accessor) {
        return order(array, accessor);
    }
}

export default Sort;