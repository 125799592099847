import { makeStyles } from "@material-ui/core/styles";

const HomeStyles = theme => ({
    mainArticle: {
        minWidth:'60vw'
    },
    mediaTitle: {
        fontWeight: 'bold',
        fontSize: '24px',
        textOverflow: 'ellipsis',
        opacity: '0.8',
        color: 'white',
        fontSize: '16px',
    },
    mediaCaption: {
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        fontSize: '12px',
        color: 'white',
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing.unit * 8,
        padding: `${theme.spacing.unit * 6}px 0`,
        maxHeight: '100px'
    }
});

export default makeStyles(HomeStyles);