import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import ApiClient from 'silly-stacks-ducks/ApiClient';

const poolData = {
    UserPoolId: 'us-east-1_xOIXKLu08',
    ClientId: '2bl6032q0qt1c0al9gdmee790g',
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export function getCognitoUser(username) {
    const userData = {
        Username: username,
        Pool: userPool,
    };
    return new AmazonCognitoIdentity.CognitoUser(userData);
}

export function getCachedSession() {
    const user = userPool.getCurrentUser();

    if (!user) {
        return null;
    }

    const accessToken = new AmazonCognitoIdentity.CognitoAccessToken({ AccessToken: getFromLocalStore(user, ACCESS_TOKEN) });
    const idToken = new AmazonCognitoIdentity.CognitoIdToken({ IdToken: getFromLocalStore(user, ID_TOKEN) });
    const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: getFromLocalStore(user, REFRESH_TOKEN) });

    const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken
    };

    return new AmazonCognitoIdentity.CognitoUserSession(sessionData);
}

export function getAccessToken() {

    return new Promise((resolve, reject) => {

        const cachedSession = getCachedSession();

        if (!cachedSession) {
            reject('Unable to retreive session');
        }

        if (cachedSession.accessToken.getExpiration() > Math.round(new Date() / 1000)) {
            resolve(cachedSession.accessToken.getJwtToken());
        }
        else {
            userPool.getCurrentUser().refreshSession(cachedSession.refreshToken, (err, session) => {
                if (err) {
                    reject(err);
                }
                resolve(session.accessToken.getJwtToken());
            });
        }
    });

}

export function authenticateUser(username, password, loginSuccess) {
    return new Promise((resolve, reject) => {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData,
        );
        const cognitoUser = getCognitoUser(username);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                var accessToken = result.getAccessToken().getJwtToken();
                var idToken = result.getIdToken().getJwtToken();
                var refreshToken = result.getRefreshToken().getToken();
                loginSuccess(username, cognitoUser, accessToken, idToken, refreshToken);
                resolve(cognitoUser);
            },
            onFailure: function (err) {
                reject(err.message);
            },
        });
    });
}

export function signUp(username, password) {
    return new Promise((resolve, reject) => {
        var attributeList = [];

        userPool.signUp(username, password, attributeList, null, function (
            err,
            result,
        ) {
            if (err) {
                reject(err);
                return;
            }
            else {
                ApiClient.account.addUserToUserGroup(result.userSub);
                resolve(result.user);
            }

        });
    });
}

export function isUserAuthorizedFor(role) {
    let session = getCachedSession();

    if (!session) {
        return false;
    }

    const userRoles = session.idToken.payload['cognito:groups'];

    return userRoles.includes(role);
};

const ACCESS_TOKEN = 'accessToken';
const ID_TOKEN = 'idToken';
const REFRESH_TOKEN = 'refreshToken';

function getFromLocalStore(user, attribute) {
    return user.storage[user.keyPrefix + '.' + user.username + '.' + attribute];

}
