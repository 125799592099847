import { makeStyles } from "@material-ui/core/styles";

const confirmationStyles ={
    confirmation: {
        width: 340
    },
    button: {
        minWidth: '340px'
    }
};

export default makeStyles(confirmationStyles);