const Pages = {
    ACCOUNT_INFO: {
        menuName: 'Account Info',
        route: '/account/info',
        title: 'Account Info',
        label: 'Account Info'
    },
    ACCOUNT_NOTIFICATIONS: {
        menuName: 'Account Notifications',
        route: '/account/notifications',
        title: 'Account Notifications',
        label: 'Account Notifications'
    },
    ACCOUNT_PLANS: {
        menuName: 'Account Plans',
        route: '/account/plans',
        title: 'Account Plans',
        label: 'Account Plans'
    },
    ACCOUNT_REDEEM: {
        menuName: 'Account Redeem',
        route: '/account/redeem',
        title: 'Account Redeem',
        label: 'Account Redeem'
    },
    ADMIN_DASHBOARD: {
        route: '/admin',
        title: 'Admin',
        label: 'Admin'
    },
    ADMIN_GAME_ADD: {
        route: '/admin/game/add',
        title: 'Add Game',
        label: 'AddGame'
    },
    ADMIN_GAME_EDIT: {
        route: '/admin/game/:id/edit',
        title: 'Admin Game Edit',
        label: 'Admin Game Edit',
        getRoute: (id, viewOnly) => `/admin/game/${id}/edit${viewOnly ? '?viewOnly=true': ''}`
    },
    CONFIRMATION: {
        route: '/confirmation',
        title: 'Account Confirmation',
        label: 'Confirmation',
        unauthenticated: true,
    },
    FORGOT_PASSWORD: {
        route: '/forgotpassword',
        title: 'Reset Password',
        label: 'ForgotPassword',
        unauthenticated: true,
    },
    LOGIN: {
        menuName: 'Login',
        route: '/login',
        title: 'Login',
        label: 'Login',
        unauthenticated: true,
    },
    HOME: {
        menuName: 'Home',
        route: '/',
        title: 'Home',
        label: 'Home',
        unauthenticated: true,
    },
    PLAY_GAME: {
        route: '/play/:id',
        title: 'Play Game',
        label: 'PlayGame',
        getRoute: (id) => '/play/' + id
    },
    PRIVACY: {
        menuName: 'Privacy',
        route: '/privacy',
        title: 'Privacy',
        label: 'Privacy',
        unauthenticated: true,
    },
    REGISTRATION: {
        route: '/registration',
        title: 'Registration',
        label: 'Registration',
        unauthenticated: true,
    }
};

export default Pages;