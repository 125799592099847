import React from 'react';

import {
    CardMedia, Card, CardContent, Grid, Typography, 
} from '@material-ui/core';

import Carousel from "react-material-ui-carousel"

import { Link } from 'react-router-dom'

import { PhoneIphone } from '@material-ui/icons';

import AndroidLogo from 'resources/google-play-badge.svg'

import phonesWithRulesImage from 'resources/mainpage-phones.png';
// import towerGameImage from 'resources/mainpage-towergame5.png';
// import numberedBlocksImage from 'resources/mainpage-numberedblocks4.png';

import styles from './HomeStyles.js'

function ImageWithBanner(props) {
    const classes = styles();

    let carouselItems = [];

    const content = (
        <Card>
           <CardMedia
            component="img"
            height="400"
            image={props.item.Image}
            title={props.item.Name}/>
            <CardContent>
                <Typography gutterBottom component="div" className={classes.mediaTitle}>
                        {props.item.Name}
                </Typography>
                <Typography gutterBottom component="div" className={classes.mediaCaption} >
                            {props.item.Caption}
                </Typography> 
            </CardContent>
        </Card>
    )

    carouselItems.push(content);

    return (
        <Grid>
            {carouselItems}
        </Grid>
    )
}

const items = [
    {
        Name: "A Classic, Reimagined",
        Caption: "The classic tumbling towers game with a twist. Pull a block, do what it says. Sounds easy, right?",
        ContentPosition: "bottom",
        Image: phonesWithRulesImage
    },
    {
        Name: "Getting Started is Easy",
        Caption: "Download the app for free. Works with numbered or unnumbered blocks.",
        ContentPosition: "bottom",
        Image: phonesWithRulesImage // was "numberedBlocksImage"
    },
    {
        Name: "Start Playing",
        Caption: "Hundreds of unique rules and a growing variety of game types to pick from. Choose from bar games, Death Cup, Cups of Fire, Couples",
        ContentPosition: "bottom",
        Image: phonesWithRulesImage // was "towerGameImage"
    }
]

export default function Home() {

    const classes = styles();
  
    return (
        <div style={{
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            opacity: 0.9,
            paddingBottom: '100px'
        }}>
            <Grid container justify='center' alignItems='center' style={{display: 'flex', marginTop: '24px'}}>
                <article className={classes.mainArticle}>
                    <Carousel
                        className="MainCarousel"
                        autoPlay={false}
                        indicators={true}
                        timeout={500}
                        cycleNavigation={true}
                        navButtonsAlwaysVisible={false}
                        navButtonsAlwaysInvisible={false}
                    >
                        {
                            items.map((item, index) => {
                                return <ImageWithBanner item={item} key={index} contentPosition={item.contentPosition} />
                            })
                        }
                    </Carousel>

                    <div>
                        <PhoneIphone style={{display: 'block', margin:'auto',fontSize:'96px', paddingTop: '12px'}}/>
                        <br/>
                    </div>

                    <Typography style={{display: 'block', margin:'auto', alignItems: 'center', justify:'center', textAlign: 'center'}} variant="h4" component="h2">Get the App</Typography>
                    <div style={{display: 'block', margin:'auto', alignItems: 'center', justify:'center', textAlign: 'center', margin:'25px'}}>
                        <Link style={{margin:'20px'}} to='/'>
                            <img src={AndroidLogo} style={{maxWidth: '135px', marginBottom:'1px'}} alt="AndroidLogo"></img>
                        </Link>
                    </div>

                </article>
            </Grid>
        </div>
    );
}