import React from 'react';

import { Grid } from '@material-ui/core';

export default function ForgotPasswordPage(props) {
    return (
        <Grid container justify="center" alignItems="center" spacing={1} >
            <Grid item xs={12}>
                This is a card
            </Grid>
        </Grid>
    );
}
