import React from 'react';

import { Button, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { GenericReducer } from 'helpers';
import { OutlinedTextInput } from 'components';
import { Pages } from 'constant';
import styles from './confirmationStyles.js';

const initState = () => {
    return {
        confirmationCode: '',
        confirmationCodeError: true
    };
};

export default function ConfirmationPage(props) {
    const classes = styles();
    const [state, updateState] = React.useReducer(GenericReducer, initState());
    const cognitoUser = useSelector(state => state.login.cognitoUser);

    const onCodeChange = (event) => {
        updateState({
            confirmationCode: event.target.value,
            confirmationCodeError: event.target.value === ''
        });
    };

    const confirmAccount = () => {
        if (state.confirmationCodeError) {
            alert('Please enter a valid code');
            return;
        }

        cognitoUser.confirmRegistration(
            state.confirmationCode.trim(),
            true,
            err => {
                if (err) {
                    alert(err.message);
                    return;
                }
            },
        );
        props.history.push(Pages.LOGIN.route);
    };

    return (
        <Grid container justify="center" alignItems="center" spacing={1} className={classes.root}>
            <Grid item container xs={4}>
                <Grid item xs={12}>
                    <p>An email has been sent to you with a confirmation code.</p>
                    <OutlinedTextInput
                        label='Confirmation Code'
                        value={state.confirmationCode}
                        onChange={onCodeChange}
                        className={classes.confirmation}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={confirmAccount}
                        fillWidth>
                        Confirm Account
                        </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
