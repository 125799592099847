const StoreAvailabilityTypes = {
    Unavailable: 0,
    Pending: 1,
    Available: 2,
    getName(type) {
        switch (type) {
            case this.Available:
                return "Available";
            case this.Pending:
                return "Pending";
            case this.Unavailable:
                return "Unavailable";
            default:
                return "Unknown";
        }
    },
    getSelectOptions() {
        return [
            {label: "Available", value: this.Available},
            {label: "Pending", value: this.Pending},
            {label: "Unavailable", value: this.Unavailable},
        ]   
    }
}

export default StoreAvailabilityTypes;