import React, { useState } from "react";
import { Divider, Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Pages } from 'constant';

import { isUserAuthorizedFor } from 'helpers/AuthenticationHelper';
import styles from './navbarStyles.js';

const { HOME, PRIVACY, ADMIN_DASHBOARD } = Pages;

function DrawerComponent() {
  const classes = styles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isAdmin = isUserAuthorizedFor("Admin");

  return (
    <>
      <Drawer
        width="50%"
        anchor='right'
        variant='persistent'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                <Link className={classes.menuLink} to={HOME.route}>{HOME.title}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link className={classes.menuLink} to={PRIVACY.route}>{PRIVACY.title}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          {isAdmin &&
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link className={classes.menuLink} to={ADMIN_DASHBOARD.route}>{ADMIN_DASHBOARD.title}</Link>
            </ListItemText>
          </ListItem>
          }
          {isAdmin &&
          <Divider/>}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
