import React from "react";
import { AppBar, Container, Toolbar, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import DrawerComponent from "./Drawer";
import { Link } from "react-router-dom";
import { Pages } from 'constant';

import { isUserAuthorizedFor } from 'helpers/AuthenticationHelper';
import logo from 'resources/website-home-logo.svg';
import styles from './navbarStyles.js';

const { HOME, PRIVACY, ADMIN_DASHBOARD } = Pages;

export default function Navbar() {
  const classes = styles();
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const isAdmin = isUserAuthorizedFor("Admin");

  return (
    <AppBar className={classes.appBar} position='sticky'>
        <Container maxWidth="lg" >
            <Toolbar>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.logo} >
                    <Link className={classes.menuLink} to='/'>
                        <img src={logo} alt="logo"></img>
                    </Link>
                </Typography>

                {isMobileWidth ? (
                <DrawerComponent />
                ) : (
                <div className={classes.menuLinkList}>
                    <Typography component="h1" variant="h6" noWrap style={{ padding: '0.5em' }} >
                        <Link className={classes.menuLink} to={HOME.route}>{HOME.title}</Link>
                    </Typography>
                    <Typography component="h1" variant="h6" noWrap style={{ padding: '0.5em' }} >
                        <Link className={classes.menuLink} to={PRIVACY.route}>{PRIVACY.title}</Link>
                    </Typography>
                    {isAdmin &&
                    <Typography  component="h1" variant="h6" noWrap style={{ padding: '0.5em' }} >
                        <Link className={classes.menuLink} to={ADMIN_DASHBOARD.route}>{ADMIN_DASHBOARD.title}</Link>
                    </Typography>
                    }
                </div>
                )}
            </Toolbar>
        </Container>
    </AppBar>
  );
}
