import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import {
    Button, Grid, Paper, TextField, Select, FormControl, InputLabel,
    FormControlLabel, Checkbox, Typography, IconButton, InputAdornment, Tooltip
} from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import * as gameDuck from 'silly-stacks-ducks/Game';

import { Pages, FieldNames, ObjectProperties, StoreAvailabilityTypes } from 'constant';
import { GenericReducer, get, ImmutableHelper } from 'helpers';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import styles from './gameEditStyles.js';
import {useQuery} from 'helpers/NavigationHelper';

const stateFields = ObjectProperties.Game;

const newGame = () => {
    return {
        blocks: [],
        category: '',
        description: '',
        googlePlayProductId: '',
        isAlexaEnabled: false,
        isNSFW: false,
        storeAvailability: StoreAvailabilityTypes.Pending,
        levels: [],
        lossPenalty: '',
        name: '',
    };
}

export default function GameEditPage(props) {
    const classes = styles();
    const id = props.match.params["id"];
    const query = useQuery();
    const viewOnly = query.get('viewOnly');
    const gameActions = bindActionCreators(gameDuck, useDispatch());
    const gameFromRedux = useSelector(state => state.game.games.find(r => r && r.id === id));
    const [game, updateGame] = React.useReducer(GenericReducer);
    const [preview, updatePreview] = React.useState('');
    const [image, updateImage] = React.useState(undefined);

    useEffect(() => {
        if (id) {
            updateGame(ImmutableHelper.object.deepClone(gameFromRedux));
            updatePreview(`https://silly-stacks-images.s3.amazonaws.com/${id}/logo/logo.png`);
        }
        else {
            updateGame(newGame());
        }
    }, [id, gameFromRedux]);

    const validateGame = () => {
        var reason = '';
        if (!game.name) {
            reason += 'You must input a name.  ';
        }
        if (!id && !image) {
            reason += ("You must select an image.  ");
        }

        return { valid: reason.length === 0, reason: reason };
    }

    const save = () => {
        var validate = validateGame();
        if (!validate.valid) {
            alert(validate.reason);
            return;
        }

        const saveGame = id ? gameActions.updateGame(id, game) : gameActions.addGame(game);
        saveGame
            .then(gameResponse => {
                if (gameResponse !== undefined) {
                    if (image !== undefined) {
                        gameActions.updateImage(gameResponse.data.id, image)
                            .then(imageResponse => {
                                if (imageResponse !== undefined) {
                                    goHome();
                                }
                            });
                    }
                    else goHome();
                }
            });
    }

    const goHome = () => {
        props.history.push(Pages.ADMIN_DASHBOARD.route);
    }

    const updateForm = (path, value) => {
        updateGame({ path: path, value: value })
    }

    const updateAndPreviewImage = (image) => {
        updateImage(image);

        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = event => {
            updatePreview(reader.result);
        }
    }

    const addBlock = (levelId) => {
        let path = stateFields.blocks;
        let count = get(game, path).length;
        updateForm(path + '.' + count, {
            action: '',
            levelId: levelId
        });
    }

    const removeBlock = (index) => {
        updateForm(
            stateFields.blocks,
            ImmutableHelper.array.removeItem(game.blocks, index)
        );
    }

    const updateBlock = (idx, value) => {
        var path = stateFields.blocks + '.' + idx + '.' + stateFields.blockAction;
        updateForm(path, value);
    }

    const updateLevelPenalty = (idx, value) => {
        var path = stateFields.levels + '.' + idx + '.' + stateFields.levelPenalty;
        updateForm(path, value);
    }

    const deleteLevel = (idx) => {
        updateForm(
            stateFields.levels,
            ImmutableHelper.array.removeItem(game.levels, idx)
        );
    }

    const addLevel = () => {
        let count = get(game, stateFields.levels).length;
        updateForm(stateFields.levels + '.' + count, { 'id': count + 1, penalty: '' });
    }

    const imageName = () => {
        if (image !== undefined) return image.name;
        else if (preview !== '') return 'logo.png';
        else return '';
    }

    const renderBlocks = (levelId) => {
        let view = [];

        for (var i = 0; i < game.blocks.length; i++) {
            let b = game.blocks[i];
            const index = i;
            if (b.levelId === levelId) {
                var blockView = (
                    <Grid item xs={4} key={index}>
                        <TextField
                            disabled={viewOnly}
                            label="Action"
                            value={b.action}
                            margin="normal"
                            onChange={e => updateBlock(index, e.target.value)}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title={FieldNames.deleteBlock}>
                                            <IconButton
                                                disabled={viewOnly}
                                                aria-label='delele'
                                                color='secondary'
                                                onClick={() => removeBlock(index)}>
                                                <RemoveCircleOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                );

                view.push(blockView);
            }
        }

        return view;
    }

    const renderLevel = (level, idx) => {

        return (
            <Paper style={{ padding: 10 }}>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <Typography variant="h6">Level {idx + 1}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {!viewOnly &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => deleteLevel(idx)}
                                fullWidth>
                                Delete Level
                        </Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={viewOnly}
                            id={"levelLoss" + level.id}
                            label="Level Loss Penalty"
                            value={level.penalty}
                            margin="normal"
                            fullWidth
                            onChange={e => updateLevelPenalty(idx, e.target.value)}
                        />
                    </Grid>
                    {renderBlocks(level.id)}
                    <Grid item xs={12}>
                        {!viewOnly &&
                            <Button disabled={viewOnly} variant="contained" color="secondary" onClick={() => addBlock(level.id)}>Add Block</Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const renderDetails = (game) => {
        return (
            <Paper>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid container item spacing={4}>
                        <Grid item xs={4} >
                            <TextField
                                disabled={viewOnly}
                                id="name"
                                label="Name"
                                value={game.name}
                                margin="normal"
                                onChange={(e) => updateForm(stateFields.name, e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                disabled={viewOnly}
                                id="category"
                                label="Category"
                                value={game.category}
                                margin="normal"
                                fullWidth
                                onChange={(e) => updateForm(stateFields.category, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                checked={game.isNSFW}
                                disabled={viewOnly}
                                control={<Checkbox color="primary" />}
                                label="Is NSFW"
                                onChange={(e) => updateForm(stateFields.isNSFW, e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={4}>
                        <Grid item xs={4}>
                            <FormControl fullWidth style={{ marginTop: "16px" }}>
                                <InputLabel>Store Availability</InputLabel>
                                <Select
                                    disabled={viewOnly}
                                    native
                                    value={game.storeAvailability}
                                    label="Store Availability"
                                    inputProps={{
                                        name: 'Store Availability',
                                    }}
                                    onChange={(e) => updateForm(stateFields.storeAvailability, parseInt(e.target.value))}
                                >
                                    {StoreAvailabilityTypes.getSelectOptions().map(x => <option key={x.value} value={x.value}>{x.label}</option>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                disabled={viewOnly}
                                id="googlePlayProductId"
                                label="Google Play Product Id"
                                value={game.googlePlayProductId}
                                margin="normal"
                                fullWidth
                                onChange={(e) => updateForm(stateFields.googlePlayProductId, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FormControlLabel
                                disabled={viewOnly}
                                checked={game.isAlexaEnabled}
                                control={<Checkbox color="primary" />}
                                label="Is Alexa Enabled"
                                onChange={(e) => updateForm(stateFields.isAlexaEnabled, e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={4}>
                        <Grid item xs={8}>
                           
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                checked={game.isFreeToPlay}
                                disabled={viewOnly}
                                control={<Checkbox color="primary" />}
                                label="Free To Play"
                                onChange={(e) => updateForm(stateFields.isFreeToPlay, e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={4}>
                        <Grid item xs={8}>
                            <TextField
                                id="description"
                                disabled={viewOnly}
                                label="Description"
                                value={game.description}
                                margin="normal"
                                fullWidth
                                onChange={(e) => updateForm(stateFields.description, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth style={{ marginTop: "16px" }}>
                                <InputLabel>Store Availability</InputLabel>
                                <Select
                                    disabled={viewOnly}
                                    native
                                    value={game.storeAvailability}
                                    label="Store Availability"
                                    inputProps={{
                                        name: 'Store Availability',
                                    }}
                                    onChange={(e) => updateForm(stateFields.storeAvailability, parseInt(e.target.value))}
                                >
                                    {StoreAvailabilityTypes.getSelectOptions().map(x => <option key={x.value} value={x.value}>{x.label}</option>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <input
                                disabled={viewOnly}
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={i => updateAndPreviewImage(i.target.files[0])}
                            />
                            <label className={classes.uploadButton} htmlFor="contained-button-file">
                                <Button disabled={viewOnly} variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                                    Image Upload *
                            </Button>
                            </label>
                            <TextField required className={classes.imageName} disabled id="standard-disabled" value={imageName()} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {preview &&
                            <img alt="Preview" className={classes.imagePreview} width={200} height={100} src={preview} />
                        }
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    const enableEdit = () => {
        props.history.push(Pages.ADMIN_GAME_EDIT.getRoute(game.id, false));
    }

    if (game) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {renderDetails(game)}
                </Grid>
                {game.levels.map((l, idx) =>
                    <Grid item xs={12} key={idx}>
                        {renderLevel(l, idx)}
                    </Grid>)}
                <Grid item xs={12}>
                    {viewOnly &&
                        <Button onClick={enableEdit} variant="contained" color="primary" style={{ marginBottom: '10px' }} fullWidth>
                            Enable Edit
                            </Button>
                    }
                    {!viewOnly &&
                        <Button disabled={viewOnly} variant="contained" color="primary" onClick={addLevel} fullWidth>
                            Add Level
                            </Button>
                    }
                    {!viewOnly &&
                        <Button disabled={viewOnly} variant="contained" color="secondary" onClick={save} fullWidth style={{ marginTop: '10px' }}>
                            Save
                            </Button>
                    }
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <div>
                <h1> Loading </h1>
            </div>
        );
    }
}
