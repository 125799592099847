import React from 'react';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { FieldNames } from 'constant';
import styles from './scoreboardStyles.js';

export default function Scoreboard(props) {
  const theme = useTheme();
  const classes = styles();
  const isFlipPhone = useMediaQuery(theme.breakpoints.only('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.only('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('lg'));

  const calculateResponsiveValue = (flip, mobile, tablet, desktop, projector) => {
    if (isFlipPhone) return flip;
    else if (isMobile) return mobile;
    else if (isTablet) return tablet;
    else if (isDesktop) return desktop;
    else return projector;
  };

  const scoreboardHeight = calculateResponsiveValue('150px', '200px', '250px', '300px', '400px');
  const infoVariant = calculateResponsiveValue('subtitle1', 'subtitle1', 'h5', 'h4', 'h3');
  const orVariant = calculateResponsiveValue('h5', 'h5', 'h4', 'h3', 'h2');
  const titleVariant = calculateResponsiveValue('subtitle2', 'subtitle2', 'h6', 'h5', 'h4');

  function scoreboardWithPenalty() {
    return (
      <Grid container className={classes.dividerContainer}>
        <Grid item xs={6} className={classes.card}>
          <Typography variant={titleVariant} className={classes.actionTitle}>{FieldNames.action}</Typography>
          <Typography variant={infoVariant} className={classes.scoreboardText}>
            {props.action}
          </Typography>
        </Grid>
        <Box height={scoreboardHeight} className={classes.divider}>
          <Box style={{ height: "40%" }} width={2} mx={1} className={classes.partialDivider} />
          <Box style={{ height: "20%" }} width={6} className={classes.or}>
            <Typography variant={orVariant}>
              {FieldNames.or}
            </Typography>
          </Box>
          <Box style={{ height: "40%" }} width={2} mx={1} className={classes.partialDivider} />
        </Box>
        <Grid item xs={6} className={classes.card}>
          <Typography variant={titleVariant} className={classes.penaltyTitle}>{FieldNames.penalty}</Typography>
          <Typography variant={infoVariant} className={classes.scoreboardText}>
            {props.penalty}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  function scoreboardWithoutPenalty() {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.card}>
          <Typography variant={titleVariant} className={classes.blockActionCardTitle}>{FieldNames.action}</Typography>
          <Typography variant={infoVariant} className={classes.scoreboardText}>
            {props.action}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  function scoreboard() {
    if (props.penalty === null || props.penalty === undefined || props.penalty === '') return scoreboardWithoutPenalty();
    else return scoreboardWithPenalty();
  };

  return (
    <div className={classes.stickToBottom}>
      <Paper elevation={1} square className={classes.scoreboard}>
        {scoreboard()}
      </Paper>
    </div>
  );
}