import React from 'react';

import { bindActionCreators } from 'redux';
import { Button, Grid, FormControl, InputLabel, Select, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import GameOverDialog from '../GameOverDialog/GameOverDialog';
import { Pages, FieldNames } from 'constant'
import { GenericReducer } from 'helpers';
import Scoreboard from '../Scoreboard/Scoreboard'
import styles from './playGameStyles.js';

import * as gameDuck from 'silly-stacks-ducks/Game';

const initState = (id) => {
    return {
        id: id,
        currentBlock: {
            blockNumber: '',
            action: ''
        },
        currentAction: 'Select a block to begin!',
        currentLevel: {
            penalty: '',
            id: ''
        },
        currentPenalty: 'Select a block to begin!',
        open: false
    };
};

export default function PlayGamePage(props) {
    const classes = styles();
    const id = props.match.params["id"];
    const gameActions = bindActionCreators(gameDuck, useDispatch());
    const [state, updateState] = React.useReducer(GenericReducer, initState(id));
    const game = useSelector(state => state.game.games.find(r => r && r.id === id));

    if (!game) gameActions.getAllStoreGames();

    const allBlockNumbers = () => game.blocks.map(b => b.blockNumber).sort(function (a, b) { return a - b; });

    const lookupLevel = (blockNumber) => {
        let block = lookupBlock(blockNumber);
        let level = game.levels.find(l => l.id === block.levelId);
        updateState({
            currentLevel: {
                penalty: level.penalty,
                id: level.id
            }
        });
        return level;
    };

    const lookupBlock = (blockNumber) => {
        return game.blocks
            .find(b => b.blockNumber === parseInt(blockNumber));
    };

    const handleChange = name => event => {
        switch (name) {
            case 'getBlock': {
                const blockNumber = event.target.value;

                if (isNaN(parseInt(blockNumber))) break;
                const block = lookupBlock(blockNumber);
                updateState({
                    currentBlock: block,
                    currentLevel: lookupLevel(blockNumber)
                });
                break;
            }
            default: {
                console.log("ERROR handling change");
            }
        }
    };

    const updateScoreboard = () => {
        const action = state.currentBlock.action;
        const penalty = state.currentLevel.penalty;
        updateState({
            currentAction: action,
            currentPenalty: penalty
        });
    };

    const gameOver = () => {
        return (
            <div>
                <br />
                <Button onClick={handleOpen} className={classes.gameOverButton}>
                    {FieldNames.stackTipped}
                </Button>
            </div>
        );
    };

    const nsfw = (game) => {
        if (game.isNSFW === true) {
            return <div className={classes.nsfw}>{FieldNames.nsfw}</div>;
        }
        else return <div></div>;
    };

    const handleClose = (leave) => {
        updateState({ open: false });
        if (leave) props.history.push(Pages.ADMIN_DASHBOARD.route);
    };

    const handleOpen = () => {
        updateState({ open: true });
    };

    if (game) {
        return (
            <Grid container justify="center" alignItems="center" className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom className={classes.name}>
                        {game.name}{nsfw(game)}
                    </Typography>
                    <FormControl variant="outlined" className={classes.blockForm}>
                        <InputLabel id="block-number">{FieldNames.block}</InputLabel>
                        <Select
                            labelId="block-number"
                            label={FieldNames.block}
                            native
                            onChange={handleChange('getBlock')}
                            inputProps={{
                                name: 'blockNumber',
                                id: 'block-number'
                            }}
                        >
                            <option value="" />
                            {allBlockNumbers().map(num =>
                                <option key={num} value={num}>{num}</option>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={updateScoreboard}
                        className={classes.goButton}
                    >
                        {FieldNames.go}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {gameOver()}
                </Grid>
                <Scoreboard action={state.currentAction} penalty={state.currentPenalty} />
                <GameOverDialog open={state.open} handleClose={handleClose} gameOverPenalty={game.lossPenalty} />
            </Grid>
        );
    }
    else return (<div>LOADING</div>);
}
