import React from 'react';

import { Grid, List, ListItemText, MenuItem, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Switch } from 'react-router';

import { AuthorizedRoute } from 'components';
import GameEditPage from '../Game/GameEdit/GameEditPage';
import GamesPage from '../Game/Games/GamesPage';
import { Pages } from 'constant';
import PlayGamePage from '../Game/PlayGame/PlayGamePage/PlayGamePage';
import Roles from 'constant/Roles';

const menuItems = [
    Pages.ADMIN_DASHBOARD
];

export default function DashboardPage(props) {
    return (
        <Grid container spacing={4} style={{ padding: 25 }}>
            <Grid item xs={2}>
                <Paper>
                    <List>
                        {menuItems.map(item =>
                            <MenuItem key={item.title} button component={Link} to={item.route}>
                                <ListItemText primary='Games' />
                            </MenuItem>
                        )}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={10}>
                <Switch>
                    <AuthorizedRoute exact role={Roles.admin} path={Pages.ADMIN_DASHBOARD.route} component={GamesPage} />
                    <AuthorizedRoute role={Roles.admin} path={Pages.ADMIN_GAME_EDIT.route} component={GameEditPage} />
                    <AuthorizedRoute role={Roles.admin} path={Pages.ADMIN_GAME_ADD.route} component={GameEditPage} />
                    <AuthorizedRoute role={Roles.admin} path={Pages.PLAY_GAME.route} component={PlayGamePage} />
                </Switch>
            </Grid>
        </Grid>
    );
}