const FieldNames = {
    
    lossPenalty: 'Game Over Penalty',
    NSFW: '18+',
    advancedGame: 'Advanced Game',

    
    level: 'Group',
    addLevel: 'Add Group',
    deleteLevel: 'Delete Group',
    levelPenalty: 'Penalty',

    block: 'Block',
    blocks: 'Blocks',
    addBlock: 'Add Block',
    deleteBlock: 'Delete Block',

    stackTipped: 'I tipped the stack!',
    nsfw: '18+',
    go: 'Go',
    penaltyReveal: 'Reveal your penalty',
    gameOverGreeting: 'You Fool!',
    gameOver: 'Game Over',
    exit: 'Exit',
    back: 'Back',
    action: 'Action',
    penalty: 'Penalty',
    or: 'OR'

}


export default FieldNames;