import { makeStyles } from "@material-ui/core/styles";
import { Colors } from 'constant';

const { BACKGROUND } = Colors;

const gameOverDialogStyles = {
    appBar: {
        position: 'relative'
    },
    back: {
        color: BACKGROUND
    },
    contentContainer: {
        minHeight: '250px',
        padding: '0px',
        position: 'relative',
        textAlign: 'center',
        width: '100%'
    },
    content: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%'
    },
    leave: {
        color: 'red'
    },
    penaltyText: {
        color: 'red'
    }
};

export default makeStyles(gameOverDialogStyles);