import React from 'react';

import { bindActionCreators } from 'redux';
import { Button, Checkbox, FormControlLabel, Grid, Link, CardMedia, Card, CardContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { authenticateUser, getCognitoUser } from 'helpers/AuthenticationHelper';
import { GenericReducer } from 'helpers';
import LoadingIndicator from 'components/LoadingIndicator';
import { onEnterKeyPress } from 'helpers';
import { OutlinedTextInput, OutlinedPasswordInput } from 'components';
import { Pages } from 'constant';
import 'scss/pages/login/login.scss';
import styles from './loginStyles.js';

import * as loginDuck from 'silly-stacks-ducks/Login';
import * as mainContentDuck from 'silly-stacks-ducks/MainContent';

const emailFromStorage = localStorage.getItem('email') ? localStorage.getItem('email') : '';
const passwordFromStorage = localStorage.getItem('password') ? localStorage.getItem('password') : '';

const initState = () => {
    return {
        email: emailFromStorage,
        password: passwordFromStorage,
        emailError: emailFromStorage === '',
        passwordError: passwordFromStorage === '',
        showPassword: false,
        savePassword: emailFromStorage !== '' && passwordFromStorage !== ''
    };
};

export default function LoginPage(props) {
    const dispatch = useDispatch()
    const classes = styles();
    const [state, updateState] = React.useReducer(GenericReducer, initState());
    const loginActions = bindActionCreators(loginDuck, dispatch);
    const mainContentActions = bindActionCreators(mainContentDuck, dispatch);

    const loginSuccess = (username, cognitoUser, accessToken, idToken, refreshToken) => loginActions.loginSuccess(username, cognitoUser, accessToken, idToken, refreshToken);
    const setUserAttributes = (userAttributes) => loginActions.setUserAttributes(userAttributes);
    const setCognitoUser = (cognitoUser, forgotPasswordObject) => loginActions.setCognitoUser(cognitoUser, forgotPasswordObject);
    const setLoading = isLoading => mainContentActions.setLoading(isLoading);

    const onEmailChange = event => {
        updateState({
            email: event.target.value,
            emailError: event.target.value.trim() === ''
        });
    }

    const onPasswordChange = event => {
        updateState({
            password: event.target.value,
            passwordError: event.target.value.trim() === ''
        });
    }

    const onSavePasswordChange = () => {
        updateState({ savePassword: !state.savePassword });
    }

    const saveCredentials = (cognitoUser) => {
        if (state.savePassword) {
            localStorage.setItem('email', state.email.toLowerCase().trim());
        }
        localStorage.setItem('token', cognitoUser.signInUserSession.idToken.jwtToken);
        localStorage.setItem('user_id', cognitoUser.signInUserSession.idToken.payload.sub);
        localStorage.setItem('timestamp', cognitoUser.signInUserSession.idToken.payload.auth_time);
    }

    const finishSuccessfulLogin = (cognitoUser) => {
        cognitoUser.getUserAttributes((err, userAttributes) => {
            if (err) {
                alert(err.message);
                setLoading(false);
                return;
            }
            saveCredentials(cognitoUser);
            setUserAttributes(userAttributes);
            setLoading(false);
            props.history.push(Pages.ADMIN_DASHBOARD.route);
        });
    };

    const unsuccessfulLoginAlert = errorMessage => {
        if (errorMessage === 'User is not confirmed.') {
            const cognitoUser = getCognitoUser(state.email.trim().toLowerCase);
            setCognitoUser(cognitoUser);
            cognitoUser.resendConfirmationCode(err => {
                if (err) {
                    alert(err.message);
                    return;
                }
            });
            props.history.push(Pages.CONFIRMATION.route);
        } else {
            alert(errorMessage);
        }
    };

    const onSubmit = async () => {
        if (state.emailError) {
            alert('Please enter a valid email');
            return;
        } else if (state.passwordError) {
            alert('Please enter a valid password');
            return;
        }

        setLoading(true);
        try {
            const cognitoUser = await authenticateUser(
                state.email.toLowerCase().trim(),
                state.password.trim(),
                loginSuccess
            );
            finishSuccessfulLogin(cognitoUser);
        }
        catch (err) {
            unsuccessfulLoginAlert(err);
        }

    };

    const renderImage = () => {
        return (
            <div className={classes.imageContainer}>
                <img src={require('resources/login.svg')} alt='' width='200px' />
            </div>
        );
    };

    return (
        <Grid container justify="center" verticalAlign="center" alignItems="center" className={classes.root}>
                <Grid item container xs={4}>
                    <Card style={{marginTop:'10px', width: '100%'}}>
                        <CardContent>
                            <Grid item xs={12} >
                                {renderImage()}
                            </Grid>

                            <Grid item xs={12}>
                                <OutlinedTextInput
                                    id='email_input'
                                    label='Email'
                                    value={state.email}
                                    onChange={onEmailChange}
                                    onKeyPress={event => onEnterKeyPress(event.key, onSubmit)}
                                    className={classes.emailInput}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <OutlinedPasswordInput
                                    id='password_input'
                                    label='Password'
                                    value={state.password}
                                    onChange={onPasswordChange}
                                    onKeyPress={event => onEnterKeyPress(event.key, onSubmit)}
                                    className={classes.passwordInput}
                                />

                            </Grid>
                            <Grid container item xs={12} justify='center'>
                                <div className={classes.rememberMeWrapper}>
                                    <FormControlLabel className={classes.rememberMe} label='Remember me' control={
                                        <Checkbox color='primary' checked={state.savePassword} onChange={onSavePasswordChange} />}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.login}>
                                    {props.isLoading ?
                                        <LoadingIndicator /> :
                                        <Button
                                            size='large'
                                            color='primary'
                                            variant='contained'
                                            textTransform='none'
                                            className={classes.loginButton}
                                            onClick={onSubmit}
                                            padding='20'>
                                            Login
                                            </Button>
                                    }
                                </div>
                            </Grid>

                            <Grid item xs={12} className={classes.forgotPassword}>
                                <Link href={Pages.FORGOT_PASSWORD.route} >
                                    Forgot password?
                                    </Link>
                            </Grid>

                            <Grid item xs={12} className={classes.register}>
                                <Link href={Pages.REGISTRATION.route}  >
                                    Create new account
                                    </Link>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

        </Grid>
    );
}
