import { makeStyles } from "@material-ui/core/styles";
import { Colors } from 'constant';

const { BACKGROUND, PRIMARY_ORANGE } = Colors;

const scorebaordStyles = theme => ({
    actionTitle: {
        color: BACKGROUND,
        textAlign: 'left',
        marginLeft: '5px'
    },
    blockActionCard: {
        height: '150px',
        margin: theme.spacing(2),
        textAlign: 'center'
    },
    card: {
        maxWidth: '48.5%',
        flexBasis: '48.5%',
        display: 'grid'
    },
    divider: {
        flexGrow: '0',
        maxWidth: '3%',
        margin: '0',
        boxSizing: 'border-box',
        display: 'inline-block'
    },
    dividerContainer: {
        textAlign: 'center',
        marginTop: '25px'
    },
    gameLossCard: {
        height: '100px',
        margin: theme.spacing(1),
        textAlign: 'center'
    },
    or: {
        color: BACKGROUND,
        [theme.breakpoints.only('xs')]: {
            marginLeft: '-7px'
        },
        [theme.breakpoints.only('sm')]: {
            marginLeft: '-7px',
            paddingTop: '5px'
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: '-14px',
            paddingTop: '7px'
        },
        [theme.breakpoints.only('lg')]: {
            marginLeft: '-24px',
            paddingTop: '4px'
        },
        [theme.breakpoints.only('xl')]: {
            marginLeft: '-31px',
            paddingTop: '6px'
        },
        position: "relative"
    },
    partialDivider: {
        backgroundColor: BACKGROUND
    },
    penaltyCard: {
        height: '150px',
        margin: theme.spacing(2),
        textAlign: 'center'
    },
    penaltyTitle: {
        color: BACKGROUND,
        textAlign: 'left',
        marginLeft: '5px'
    },
    scoreboard: {
        [theme.breakpoints.only('xs')]: {
            height: '150px'
        },
        [theme.breakpoints.only('sm')]: {
            height: '200px'
        },
        [theme.breakpoints.only('md')]: {
            height: '250px'
        },
        [theme.breakpoints.only('lg')]: {
            height: '300px'
        },
        [theme.breakpoints.only('xl')]: {
            height: '400px'
        },
        backgroundColor: PRIMARY_ORANGE
    },
    scoreboardText: {
        color: BACKGROUND,
        marginBottom: '30px'
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    }
});

export default makeStyles(scorebaordStyles);