import React from 'react';

import Navbar from 'components/Navbar/Navbar';
import { Grid, Typography } from '@material-ui/core';

import { Pages } from 'constant';

import { isUserAuthorizedFor, getCachedSession } from 'helpers/AuthenticationHelper';
import styles from './layoutStyles.js';

export default function Layout(props) {
    const classes = styles();

    return (
        <div>
            <div className='app-shell' >
                <Navbar/>
                <main>
                    <Grid container>
                        <Grid item xs={12}>
                            {props.children}
                        </Grid>
                    </Grid>
                </main >
            </div >
        
            <div className={classes.footer}>
                <div className={classes.root} elevation={1}>
                    <Typography variant="h5" component="h3">
                        Kittens with Keyboards LLC
                    </Typography>
                    <Typography component="p">
                        @2021 All rights reserved
                    </Typography>
                </div>
            </div>
        </div>

    );
}
