import { makeStyles } from "@material-ui/core/styles";

const unautorizedStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
});

export default makeStyles(unautorizedStyles);