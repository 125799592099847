import React from 'react';

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core'

export default function SimpleDialog(props) {
    return (
        <Dialog open={props.open} onClose={() => props.handleClose(false)}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.negetiveAction} color="primary" autoFocus>
                    {props.negetive}
                </Button>
                <Button onClick={props.affirmativeAction} color="primary">
                    {props.affirmative}
                </Button>
            </DialogActions>
        </Dialog>
    );
}