import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import { Colors } from './constant';
import Navigation from './navigation';
import store, { history } from './ConfigureStore';

const { BACKGROUND, PRIMARY_ORANGE, SECONDARY_ORANGE } = Colors;

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary:
        {
            main: PRIMARY_ORANGE,
            light: PRIMARY_ORANGE,
            dark: PRIMARY_ORANGE
        },
        secondary:
        {
            main: SECONDARY_ORANGE,
            light: SECONDARY_ORANGE,
            dark: SECONDARY_ORANGE
        },
        background: {
            default: BACKGROUND
        }
    }
});

export default function App() {
    return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Navigation />
                    </ThemeProvider>
                </ConnectedRouter>
            </Provider>
    );
}
