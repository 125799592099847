import { makeStyles } from "@material-ui/core/styles";

import { Colors } from 'constant';

const { APP_BAR } = Colors;

const layoutStyles = theme => ({
    logo: {
        flexGrow: 1
    },
    menuLink: {
        color: 'white',
        textDecoration: 'none',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: APP_BAR
    },
    menuLinkList: {
        display: "flex",
    }
});

export default makeStyles(layoutStyles);