import React from 'react';

import { bindActionCreators } from 'redux';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { GenericReducer } from 'helpers';
import { OutlinedTextInput, OutlinedPasswordInput } from 'components';
import { Pages } from 'constant';
import { signUp } from 'helpers/AuthenticationHelper';
import styles from './registrationStyles.js'

import * as loginDuck from 'silly-stacks-ducks/Login';

const initState = () => {
    return {
        email: '',
        password: '',
        confirmedPassword: '',
        emailError: true,
        passwordError: true,
        confirmedPasswordError: true,
        confirmationOpen: false,
    };
};

export default function RegistrationPage(props) {
    const dispatch = useDispatch()
    const classes = styles();
    const [state, updateState] = React.useReducer(GenericReducer, initState());
    const loginActions = bindActionCreators(loginDuck, dispatch);

    const setCognitoUser = (cognitoUser, forgotPasswordObject) => loginActions.setCognitoUser(cognitoUser, forgotPasswordObject);

    const onEmailChange = event => {
        updateState({
            email: event.target.value,
            emailError: event.target.value.trim() === ''
        });
    }
    const onPasswordChange = event => {
        updateState({
            password: event.target.value,
            passwordError: event.target.value.trim() === ''
        });
    }
    const onConfirmedPasswordChange = event => {
        updateState({
            confirmedPassword: event.target.value,
            confirmedPasswordError: event.target.value.trim() === '' || event.target.value.trim() !== state.password.trim()
        });
    };

    const createAccount = async () => {
        try {
            const cognitoUser = await signUp(
                state.email.toLowerCase().trim(),
                state.password.trim()
            );
            setCognitoUser(cognitoUser);
            props.history.push(Pages.CONFIRMATION.route);
        } catch (err) {
            alert(err.message);
        }
    };

    const onSubmit = () => {
        if (state.emailError) {
            alert('Please enter a valid email');
            return;
        } else if (state.passwordError) {
            alert('Please enter a valid password');
            return;
        } else if (state.confirmedPasswordError) {
            alert(state.confirmedPassword === '' ? 'Please confirm password' : 'Passwords do not match');
            return;
        }

        createAccount();
    }

    const renderImage = () => {
        return (
            <div className={classes.imageContainer}>
                <img src={require('resources/login.svg')} alt='' width='200px' />
            </div>
        );
    };

    return (
        <Grid container justify="center" alignItems="center" spacing={1} className={classes.root}>
            <Grid item container xs={8}>
                <Grid item xs={12}>
                    {renderImage()}
                </Grid>

                <Grid item xs={12}>
                    <OutlinedTextInput
                        className={classes.fullTextArea}
                        label='Email *'
                        value={state.email}
                        errorCondition={state.emailError}
                        errorText='Please enter a valid email'
                        onChange={onEmailChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedPasswordInput
                        className={classes.fullTextArea}
                        label='Password *'
                        value={state.password}
                        errorCondition={state.passwordError}
                        errorText='Please enter a valid password'
                        onChange={onPasswordChange}
                        onShowPasswordChange
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedPasswordInput
                        className={classes.fullTextArea}
                        label='Confirm Password *'
                        value={state.confirmedPassword}
                        errorCondition={state.confirmedPasswordError}
                        errorText={state.confirmedPassword !== state.password ? 'Passwords do not match' : 'Please enter a valid password'}
                        onChange={onConfirmedPasswordChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={!state.confirmationOpen ? onSubmit : () => { }}
                        padding="20">
                        Create Account
                        </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
