import { makeStyles } from "@material-ui/core/styles";

const playGameStyles = theme => ({
    blockForm: {
        margin: theme.spacing(1),
        width: '275px'
    },
    gameOverButton: {
        color: 'red'
    },
    goButton: {
        width: '200px'
    },
    name: {
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: '33px'
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '37px'
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: '45px'
        },
        [theme.breakpoints.only('xl')]: {
            fontSize: '50px'
        },
    },
    nsfw: {
        color: 'red',
        position: 'relative',
        top: '-0.75em',
        display: 'inline-block',
        transform: 'rotate(45deg)',
        [theme.breakpoints.only('xs')]: {
            fontSize: '10px'
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '17px'
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: '20px'
        },
        [theme.breakpoints.only('xl')]: {
            fontSize: '25px'
        },
    },
    root: {
        textAlign: 'center'
    },
});

export default makeStyles(playGameStyles);