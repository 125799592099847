import React from 'react';

import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { FieldNames } from 'constant';
import styles from './gameOverDialogStyles.js';

export default function BlockDialog(props) {
    const [over, setOver] = React.useState(false);
    const classes = styles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const gameOver = () => {
        setOver(true);
    };

    const continueGame = () => {
        setOver(false);
    };

    const leave = () => {
        setOver(true);
        props.handleClose(true);
    };

    const back = () => {
        continueGame();
        props.handleClose(false);
    };

    const revealGameOverPenalty = () => {
        if (props.gameOverPenalty !== undefined || props.gameOverPenalty !== '') {
            return (
                <div>
                    {!over &&
                        <Button color='primary' onClick={gameOver}>
                            {FieldNames.penaltyReveal}
                        </Button>
                    }
                    {over &&
                        <Typography variant="h6" className={classes.penaltyText}>
                            {props.gameOverPenalty}
                        </Typography>
                    }
                </div>
            );
        }
        else return <div></div>
    };

    const content = () => {
        return (
            <div className={classes.content}>
                <Typography variant="h3">
                    {FieldNames.gameOverGreeting}
                </Typography>
                {revealGameOverPenalty()}
            </div>
        );
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={props.open} fullWidth onClose={() => props.handleClose(false)} aria-labelledby="responsive-dialog-title" className={classes.dialog}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={back} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {FieldNames.gameOver}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.contentContainer}>
                    {content()}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" autoFocus onClick={back} className={classes.back}>
                        {FieldNames.back}
                    </Button>
                    <Button variant="outlined" onClick={leave} className={classes.leave} autoFocus>
                        {FieldNames.exit}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
