export function get(obj, path) {

    if (!path) {
        return obj;
    }

    path = path.split('.');
    return path
        .reduce((acc, part) => {
            return acc && acc[part]
        }, obj);

}

export function set(obj, path, value) {

    let elements = path.split('.');

    get(obj, elements.slice(0, -1).join('.'))[elements[elements.length - 1]] = value;

    return obj;

}

export default function reducer(state, updateArg) {
    if (updateArg && updateArg.path) {
        const { path, value } = updateArg;
        return set({ ...state }, path, value);
    } 
    else if(!updateArg){
        return state;
    }
    else {
        return { ...state, ...updateArg };
    }
}
