import React from 'react';

import TextField from '@material-ui/core/TextField';

import styles from './outlinedTextInputStyles.js';

export default function TextInput(props) {
    const classes = styles();

    return (
        <div className={classes.inputItem}>
            <TextField
                label={props.label}
                variant="outlined"
                className={props.className}
                disabled={props.disabled}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
            />
        </div>
    );
}
