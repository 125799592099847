import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom'

import Pages from 'constant/Pages';

export default function Home() {
    return (
        <Grid container>
            <Grid item container justify='center'>
                <span>Page not found, you can return to the </span><Link to={Pages.HOME.route}>{Pages.HOME.title}</Link>
            </Grid>
        </Grid>
    );
}
