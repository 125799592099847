import { makeStyles } from "@material-ui/core/styles";
import { Colors } from 'constant';

const gameEditStyles = theme => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
      },
      input: {
        display: 'none',
      },
      imagePreview: {
        borderRadius: '5px',
        border: `1px solid ${Colors.PRIMARY_ORANGE}`
      },
      uploadButton: {
        display: 'inline',
        marginLeft: '-1px'
      },
      imageName: {
        display: 'inline'
      }
});

export default makeStyles(gameEditStyles);