import { makeStyles } from "@material-ui/core/styles";

const loginStyles ={
    root: {
        textAlign: 'center'
    },
    forgotPassword: {
        marginTop: 10,
        textAlign: 'center',
        width: 340,
        minWidth: 150
    },
    register: {
        marginTop: 10,
        textAlign: 'center',
        width: 340,
        minWidth: 150
    },
    rememberMeWrapper: {
        marginTop: 10,
        width: '100%',
        maxWidth: 340,
        minWidth: 150,
        textAlign: 'center',

    },
    rememberMe: {
        marginTop: 6,
        marginBottom: 10,
        justifyContent: 'space-between',
        float: 'left'
    },
    loginButton: {
        width: '100%',
        maxWidth: 340,
        minWidth: 150
    },
    emailInput: {
        width: '100%',
        maxWidth: 340,
        minWidth: 150
    },
    passwordInput: {
        width: '100%',
        maxWidth: 340,
        minWidth: 150
    },
    loginContainer: {
        backgroundColor: '#FFFFFF'
    }
};

export default makeStyles(loginStyles);