import { makeStyles } from "@material-ui/core/styles";

import { Colors } from 'constant';

const { PRIMARY_ORANGE } = Colors;

const outlinedTextInputStyles = {
    inputItem: {
        margin: 10
    },
    disabled: {
        backgroundColor: PRIMARY_ORANGE,
        border: (1, 'solid', PRIMARY_ORANGE),
        color: PRIMARY_ORANGE
    },
    passwordIcon: {
        fontSize: 25
    }
};

export default makeStyles(outlinedTextInputStyles);