import React, { Component } from 'react';

import { Route, Switch, withRouter } from 'react-router';

import AdminDashboardPage from 'views/Admin/Dashboard/DashboardPage';
import { AuthorizedRoute } from 'components';
import ConfirmationPage from 'views/Confirmation/ConfirmationPage';
import ForgotPasswordPage from 'views/ForgotPassword/ForgotPasswordPage';
import { getPage } from 'helpers/WebHelper';
import Layout from 'Layout';
import LoginPage from 'views/LoginPage/LoginPage';
import Home from 'views/Home';
import Privacy from 'views/Privacy';
import NotFound from 'views/NotFound';
import { Pages } from 'constant';
import 'scss/appShell.scss';
import PlayGamePage from 'views/Admin/Game/PlayGame/PlayGamePage/PlayGamePage';
import RegistrationPage from 'views/Registration/RegistrationPage';
import Roles from 'constant/Roles';

class Navigation extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path={Pages.HOME.route} component={Home} />

                    <Route exact path={Pages.PRIVACY.route} component={Privacy} />
                    <Route exact path={Pages.LOGIN.route} component={LoginPage} />
                    <Route exact path={Pages.REGISTRATION.route} component={RegistrationPage} />
                    <Route exact path={Pages.FORGOT_PASSWORD.route} component={ForgotPasswordPage} />
                    <Route exact path={Pages.CONFIRMATION.route} component={ConfirmationPage} />

                    <AuthorizedRoute exact role={Roles.admin} path={Pages.PLAY_GAME.route} component={PlayGamePage} />
                    <AuthorizedRoute role={Roles.admin} path={Pages.ADMIN_DASHBOARD.route} component={AdminDashboardPage} />

                    <Route component={NotFound} />

                </Switch>
            </Layout>
        );
    }
}

export default withRouter(Navigation);
