import React from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';

import styles from './unautorizedStyles.js';

export default function UnauthorizedPage(props) {
    const classes = styles();

    return (
        <div className={classes.root} >
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h2">
                            401 Unauthorized
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
