const ObjectProperties = {
    Game: {
        blocks: 'blocks',
        penalty: 'penalty',
        blockAction: 'action',
        levels: 'levels',
        levelPenalty: 'penalty',
        lossPenalty: 'lossPenalty',
        isNSFW: 'isNSFW',
        isAlexaEnabled: 'isAlexaEnabled',
        googlePlayProductId: 'googlePlayProductId',
        category: 'category',
        name: 'name',
        storeAvailability: 'storeAvailability',
        description: 'description',
        isFreeToPlay: 'isFreeToPlay'
    }
}

export default ObjectProperties;