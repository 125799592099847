import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { bindActionCreators } from 'redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {
     Button, Grid, IconButton, Table, TableBody, Paper,
    TableCell, TableHead, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useDispatch, useSelector } from 'react-redux';

import { Pages, StoreAvailabilityTypes } from 'constant';
import SimpleDialog from 'components/SimpleDialog';
import { Sort } from 'helpers';

import * as gameDuck from 'silly-stacks-ducks/Game';

const headers = [
    { id: 0, name: 'Name', sortable: true, property: 'name' },
    { id: 1, name: 'Category', sortable: true, property: 'category' },
    { id: 2, name: 'Store Status', sortable: false, property: 'StoreAvailability' },
    { id: 3, name: 'Actions', sortable: false },
]

export default function GamesPage(props) {
    const gameActions = bindActionCreators(gameDuck, useDispatch());
    const storeGames = useSelector(state => state.game.games);
    const [orderBy, setOrderBy] = useState(0);
    const [ascending, setAscending] = useState(true);
    const [filter, setFilter] = useState("");
    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState("");

    const handleDeleteDialogClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        gameActions.deleteGame(toDelete).then(response => {
            if (response !== undefined) {
                handleDeleteDialogClose();
                props.history.push(Pages.ADMIN_DASHBOARD.route);
            }
        });
    }

    const handleDeleteDialogOpen = (id) => {
        setToDelete(id);
        setOpen(true);
    };

    const updateSort = (columnId) => {
        setOrderBy(columnId);
        setAscending(!ascending);
    }

    const getGames = () => {
        const header = headers[orderBy];
        const orderFunction = ascending ? Sort.orderBy : Sort.orderByDescending;

        return orderFunction(storeGames.filter(x => !filter || x.name.toLowerCase().includes(filter.toLowerCase())), x => x[header.property].toLowerCase())
    }

    if (!storeGames || storeGames.length === 0) {
        gameActions.getAllStoreGames();
    }

    return (
        <>
            <Paper>
                <Grid container spacing={2} justify='space-between'>
                    <Grid item xs={6}>
                        <TextField style={{ marginLeft: '15px' }} value={filter} onChange={(event) => setFilter(event.target.value)} label='Search by name' />
                    </Grid>
                    <Grid item container xs={2} justify='flex-end'>
                        <Button color='primary' variant='outlined' component={Link} to={Pages.ADMIN_GAME_ADD.route} style={{marginRight: '15px'}}>
                        <AddIcon />
                            <Typography>Add</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headers.map(h => (
                                        <TableCell key={h.id}>
                                            {h.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === h.id}
                                                    direction={ascending ? 'asc' : 'desc'}
                                                    onClick={() => updateSort(h.id)}
                                                >
                                                    {h.name}
                                                </TableSortLabel>) :
                                                h.name
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getGames().map((game) => (
                                    <TableRow key={game.id}>
                                        <TableCell>
                                            {game.name}
                                        </TableCell>
                                        <TableCell>
                                            {game.category}
                                        </TableCell>
                                        <TableCell>
                                            {StoreAvailabilityTypes.getName(game.storeAvailability)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="play" component={Link} to={Pages.PLAY_GAME.getRoute(game.id)}>
                                                <PlayArrowIcon />
                                            </IconButton>
                                            <IconButton aria-label="details" component={Link} to={Pages.ADMIN_GAME_EDIT.getRoute(game.id, true)}>
                                                <AssignmentIcon />
                                            </IconButton>
                                            <IconButton aria-label="edit" component={Link} to={Pages.ADMIN_GAME_EDIT.getRoute(game.id, false)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => handleDeleteDialogOpen(game.id)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
            <SimpleDialog
                open={open}
                handleClose={handleDeleteDialogClose}
                title='Are you sure?'
                content='Are you sure you want to delete this game?'
                affirmative='Yes'
                affirmativeAction={handleDelete}
                negetive="No"
                negetiveAction={handleDeleteDialogClose}
            />
        </>
    );
}