import React from 'react';

import { Route } from "react-router-dom";

import { isUserAuthorizedFor } from 'helpers/AuthenticationHelper'
import UnauthorizedPage from 'views/Unauthorized/UnauthorizedPage';

export default function AuthorizedRoute(props) {

    if (isUserAuthorizedFor(props.role)) {
        return <Route {...props} />
    }
    else {
        return <UnauthorizedPage />
    }
}