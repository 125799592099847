import { makeStyles } from "@material-ui/core/styles";

import { Colors } from 'constant';

const { APP_BAR } = Colors;

const layoutStyles = theme => ({
    logo: {
        flexGrow: 1
    },
    menuLink: {
        color: 'white',
        textDecoration: 'none'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: APP_BAR
    },
    root: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing.unit * 2,
        height: '80'
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: '20px',
        backgroundColor: '#15191A',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
    }
});

export default makeStyles(layoutStyles);